<script setup>
import { useAuthStore } from '~/store/auth.js'
import axiosProclaimService from '~/services/axios.proclaim.api.service.js'

const { $Echo } = useNuxtApp()

const isProclaimDown = computed(() => useAuthStore().isProclaimDown)
const isProclaimDownCounter = ref(1)
const proclaimTimeout = ref(null)

onMounted(async () => {
  $Echo?.channel('App.Proclaim.Status')?.listen('ProclaimStatusUpdated', (event) => {
    updateStatus(event.isDown)
  })

  await getProclaimStatus()
})

onBeforeUnmount(() => {
  $Echo?.leaveChannel('App.Proclaim.Status')
})

async function getProclaimStatus() {
  try {
    const response = await axiosProclaimService.get('/status')
    updateStatus(response?.data?.is_down)
    updateCounter(1)
  } catch (error) {
    if (useAuthStore().isAuthenticated) {
      // The proclaim endpoint we use to do this check is flakey, so we try a few times before confirming proclaim is down
      if (isProclaimDownCounter.value < 4) {
        if (proclaimTimeout.value) {
          clearTimeout(proclaimTimeout)
        }

        proclaimTimeout.value = setTimeout(async () => {
          updateCounter(isProclaimDownCounter.value + 1)
          await getProclaimStatus()
        }, 500)
      } else {
        updateStatus(true)
      }
    }
  }
}

function updateStatus(isDown) {
  useAuthStore().setProclaimStatus(isDown)
}

function updateCounter(counter) {
  isProclaimDownCounter.value = counter
}
</script>

<template>
  <banner
    v-if="isProclaimDown"
    message="<strong>Service Disruption:</strong> Our team is actively working to resolve the issue. We appreciate your patience and apologize for any inconvenience this may cause."
    type="error"
    :dismissable="false"
  />
</template>
