<script setup>
const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  stateClass: {
    type: String,
    default: 'bg-primary',
  },
})
</script>

<template>
  <p class="text-shadow p-2 text-center text-white" :class="stateClass">
    {{ ucFirst(props.content) }}
  </p>
</template>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
